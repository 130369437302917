import React from 'react'
import { useParams } from 'react-router-dom'
import "./product.css"
import ZoomCard from './ZoomCard'
import NotFound from './NotFound';
import Carousel from './carousel';
export default function View() {
    var product = useParams().product
    if(product === "tmt-bar"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\TMT BAR\durgapur.jpg" name="DURGAPUR"/>
                <br/>
                <ZoomCard src="/Logo\TMT BAR\shyam.jpg" name="SHYAM"/>
                <br/>
                <ZoomCard src="/Logo\TMT BAR\tata.webp" name="TATA"/>
                <br/>
                <ZoomCard src="/Logo\TMT BAR\xtech.jpg" name="XTECH"/>
                </div>
                <div className='row'>
                <ZoomCard src="/Logo\TMT BAR\sail.png" name="SAIL"/>
                </div>
            </div>
        )
    }else if(product === "cement"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\CEMENT\acc.jpeg" name="ACC"/>
                <br/>
                <ZoomCard src="/Logo\CEMENT\ambuja.jpg" name="AMBUJA"/>
                <br/>
                <ZoomCard src="/Logo\CEMENT\dalmia.png" name="DALMIA"/>
                <br/>
                <ZoomCard src="/Logo\CEMENT\topcem.png" name="TOPCEM"/>
                </div>
                <div className='row'>
                <ZoomCard src="/Logo\CEMENT\ultratech.png" name="ULTRATECH"/>
                <br/>
                <ZoomCard src="/Logo\CEMENT\ramco.jpg" name="RAMCO"/>
                </div>
            </div>
        )
    }else if(product === "sanitary"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\SANITARY\adson.png" name="ADSON"/>
                <br/>
                <ZoomCard src="/Logo\SANITARY/hindware.png" name="HINDWARE"/>
                <br/>
                <ZoomCard src="/Logo\SANITARY\nipcotaj.jpg" name="NIPCOTAJ"/>
                <br/>
                <ZoomCard src="/Logo\SANITARY\sintex.png" name="SINTEX"/>
                </div>
                <div className='row'>
                <ZoomCard src="/Logo\SANITARY\supreme.png" name="SUPREME"/>
                </div>
            </div>
        )
    }else if(product === "ply"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\DOORS\century.jpg" name="CENTURY"/>
                <br/>
                <ZoomCard src="/Logo\DOORS\everest.jpeg" name="EVEREST"/>
                </div>
            </div>
        )
    }else if(product === "flush"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\DOORS\century.jpg" name="CENTURY"/>
                <br/>
                <ZoomCard src="/Logo\DOORS\everest.jpeg" name="EVEREST"/>
                </div>
            </div>
        )
    }else if(product === "roofing"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\ROOFING\dynaroof.jpg" name="DYNAROOF" />
                <br/>
                <ZoomCard src="/Logo\ROOFING\jindal.png" name="JINDAL"/>
                </div>
            </div>
        )
    }else if(product === "paint"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\PAINT\berger.png" name="BERGER"/>
                </div>
            </div>
        )
    }else if(product === "fabrication"){
        return(
            <div className='container'>
                <br></br>
                <div className='row'>
                <ZoomCard src="/Logo\FABRICATION\halco.png" name="HALCO"/>
                <br/>
                <ZoomCard src="/Logo\FABRICATION\gate.jpg" name="MS GATE"/>
                <br/>
                <ZoomCard src="/Logo\FABRICATION\window.webp" name="MS WINDOW"/>
                <br/>
                <ZoomCard src="/Logo\FABRICATION\door.avif" name="MS DOOR"/>
                </div>
            </div>
        )
    }else if(product==="aluminiun-door"){
        return(
            <Carousel imgarr={[
                "/Carousel/1/1.webp",
                "/Carousel/1/2.avif",
                "/Carousel/1/3.webp",
                "/Carousel/1/4.webp",
                "/Carousel/1/5.webp",
                "/Carousel/1/6.webp",
                "/Carousel/1/7.webp",
                "/Carousel/1/8.jpeg",
            ]}/>
        )
    }else if(product==="wooden"){
        return(
            <Carousel imgarr={[
                "/Carousel/2/1.webp",
                "/Carousel/2/2.jpg",
                "/Carousel/2/3.webp",
                "/Carousel/2/4.png",
                "/Carousel/2/5.webp",
                "/Carousel/2/6.webp",
                "/Carousel/2/7.webp",
            ]}/>
        )
     }
     else if(product==="aluminiun-window"){
        return(
            <Carousel imgarr={[
                "/Carousel/3/1.jpg",
                "/Carousel/3/2.webp",
                "/Carousel/3/3.webp",
                "/Carousel/3/4.png",
                "/Carousel/3/5.webp",
            ]}/>
        )
    }
    else{
        return(
            <NotFound/>
        )
    }
}
