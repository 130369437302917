import React from 'react'

export default function ZoomCard(props) {
  return (
    <>
    <div className="col-md-3">
        <div className="card">
            <h5 className="card-header">{props.name}</h5>
            <div className="image-con">
                <img src={props.src} className="w-100 image-hover" alt={props.name}/></div></div><br/>
                </div>
    </>
  )
}
