import React from 'react';
import ReactDOM from 'react-dom/client';
import Navbar from './components/Navbar';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Content from './components/content';
import NotFound from './components/NotFound';
import View from './components/view.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Navbar/>
    <Routes>
    <Route path="/" element={<Content page='home'/>}></Route>
    <Route path="/about" element={<Content page='about'/>}></Route>
    <Route path="/products" element={<Content page='products'/>}></Route>
    <Route path="/contact" element={<Content page='contact'/>}></Route>
    <Route path="/products/:product" element={<View/>}></Route>``
    <Route path="/*" exact element={<NotFound/>}></Route>
    <Route path="/*/*" exact element={<NotFound/>}></Route>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
