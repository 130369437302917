import React from 'react'
import { Link } from 'react-router-dom'
export default function Navbar() {
  const closenav = () => {
    document.querySelector('.navbar-toggler').click()
  }
  return (
    <div>
      <div className='bg-success' style={{fontWeight:'bold',width:'100%',paddingTop:'10px',paddingBottom:'10px',textAlign:'center',color:'white'}}>Customer Support :- 86389 00496</div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
    <Link className="navbar-brand display-6" style={{fontWeight:'bold'}} to="/">নীলম হাৰ্ডৱেৰ<br/>Nillum Hardware</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav ms-auto navbar-nav" >
        <li className="nav-item">
          <Link className="nav-link" onClick={() => closenav()} to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" onClick={() => closenav()} to="/about">About
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" onClick={() => closenav()} to="/products">Products
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" onClick={() => closenav()} to="/contact">Contact Us
          </Link>
        </li>
      </ul>
    </div>
  </div>
</nav>
    </div>
  )
}
