import React from 'react'
import { Link } from 'react-router-dom'
export default function Card(props) {
  return (
    <div className={"card "+props.bg}>
    <img src={props.url} className="card-img-top" alt={props.title}/>
    <div className="card-body">
      <div className="text-section">
        <h5 className="card-title">{props.title}</h5>
        <Link to={props.redirect} className={"btn "+props.color}>View More</Link>
      </div>
      <div className="cta-section" style={{textAlign: 'right'}}>
        <div>{props.assamese}</div>
      </div>
    </div>
  </div>
  )
}
