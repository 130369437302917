 function carousel(params) {
    const arr = params.imgarr
  return (
    <div style={{marginTop: "calc((100vh - 500px)/ 2)"}}>
      <div id="carouselExampleFade" className="carousel slide carousel-fade m-auto" style={{maxWidth:"500px"}}>
  <div className="carousel-inner">
    {arr.map((item, index) => {
      return (
        <div className={index === 0 ? "carousel-item active" : "carousel-item"} style={{maxHeight:"500px"}}>
        <img src={item} className="d-block w-100" alt={item}/>
      </div>
      )
    })}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  );
}

export default carousel;