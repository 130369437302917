import React from 'react'
import Footer from './footer'
import Card from './cardforproduct';
export default function Content(props) {
    const css = `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #f2f2f2;
  }
  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 30px 0;
    margin-left: 0%;
    background: white;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    animation: 35s slide infinite linear;
  }
  
  .logos-slide img {
    max-height: 50px;
    width: auto;
    margin: 0 40px;
  }
    
  .align-middle{
  color: white;
  }
  `
    if(props.page === "home"){
        return (
            <>
            <style>{css}</style>
        <div className="home" style={{height:'100%',color:'white'}}>
            <nav className="hero-container" id="hero-sec">
  <div className="container-fluid">
  <div className="row d-flex">
    <div className="col-lg-12" style={{textAlign:'center',color: "navy",backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat",width:"100%",backgroundColor:"navy",fontWeight:'bolder'}}>
      <div className="px-5 py-5">
        <div className="px-2 py-2 align-middle">
        <h4 style={{fontWeight:'bold',fontSize:'30px'}}>নিৰ্মাণ সামগ্ৰীৰ দোকান
            <br/>
            Construction Materials Store</h4>
            <p className="lead" style={{fontWeight:'bold'}}>সকলো নিৰ্মাণ সামগ্ৰী এটা চালৰ তলত <br/>ALL BUILDING MATERIALS UNDER ONE ROOF</p>
            <p style={{fontWeight:'bold',fontSize:'20px'}}>Our Store Provide Best Quality<br/>
        Cement, TMT Steel, AAC Blocks, Bricks & More
        <br/>
        চিমেণ্ট, টিএমটি ষ্টীল, এএচি ব্লক, ইটা</p>
        </div>
        <div className="px-2 py-2"> 
          <button type="button" onClick={()=>{window.open("https://wa.me/918638900496")}} style={{color:'white'}} className="btn btn-success"><i className="fa fa-whatsapp" style={{color:'white',fontSize:'20px'}}></i>  Let's have a Chat</button>
        </div>
      </div>
    </div>
</div>
</div>
        </nav>
        </div>
        <br />
        <h4 className='text-center'>Brands </h4>
        <div className="logos">
      <div className="logos-slide">
      <img src="/Logo/CEMENT/acc.jpeg" alt="ACC" />
      <img src="/Logo/CEMENT/ambuja.jpg" alt="AMBUJA" />
      <img src="/Logo/CEMENT/dalmia.png" alt="DALMIA" />
      <img src="/Logo/CEMENT/ramco.jpg" alt="RAMCO" />
      <img src="/Logo/CEMENT/topcem.png" alt="TOPCEM" />
      <img src="/Logo/CEMENT/ultratech.png" alt="ULTRATECH" />
      <img src="/Logo/TMT BAR/tata.webp" alt="TATA" />
      <img src="/Logo/TMT BAR/xtech.jpg" alt="XTECH" />
      </div>
      <div className="logos-slide">
      <img src="/Logo/CEMENT/acc.jpeg" alt="ACC" />
      <img src="/Logo/CEMENT/ambuja.jpg" alt="AMBUJA" />
      <img src="/Logo/CEMENT/dalmia.png" alt="DALMIA" />
      <img src="/Logo/CEMENT/ramco.jpg" alt="RAMCO" />
      <img src="/Logo/CEMENT/topcem.png" alt="TOPCEM" />
      <img src="/Logo/CEMENT/ultratech.png" alt="ULTRATECH" />
      <img src="/Logo/TMT BAR/tata.webp" alt="TATA" />
      <img src="/Logo/TMT BAR/xtech.jpg" alt="XTECH" />
      </div>
    </div>
        <Footer/>
            </>
        );
    }else if(props.page === "about"){
        return (
            <>
            <div className="container w-100 h-90">
              <div className="row" style={{textAlign: 'justify',fontWeight:'bold'}}>
                <div className="col-sm-6">
                <br /><br />
                Who We Are <br />
Having 7 Years Experience <br /><br />

NILLUM HARDWARE is formed in 2017 with one of the leading & biggest range of Building materials with a wide space located in the heart of Guwahati city. Engaged in supply, wholesale, retail of vast range of construction materials, tiles, sanitary ware, fittings and bricks. We are also exclusive in steel and cement business.
<br /><br />
Our motto is to help customers select right product match through our expert guidance. We provide good quality branded material with prompt service & supply.
                </div>
                <div className="col-sm-6">
                <br /><br />
                আমি কোন <br />
৭ বছৰৰ অভিজ্ঞতা থকা <br /><br />

গুৱাহাটী চহৰৰ মাজমজিয়াত অৱস্থিত বহল স্থানৰ সৈতে বিল্ডিং মেটেৰিয়েলৰ অন্যতম আগশাৰীৰ আৰু বৃহৎ ৰেঞ্জৰ সৈতে ২০১৭ চনত নীলম হাৰ্ডৱেৰ গঠন কৰা হৈছে। নিৰ্মাণ সামগ্ৰী, টাইলছ, চেনিটেৰী ৱেৰ, ফিটিংছ আৰু ইটাৰ বিশাল পৰিসৰৰ যোগান, পাইকাৰী, খুচুৰা বিক্ৰীত নিয়োজিত। ষ্টীল আৰু চিমেণ্টৰ ব্যৱসায়তো আমি একচেটিয়া।
<br /><br />
আমাৰ মূলমন্ত্ৰ হৈছে আমাৰ বিশেষজ্ঞৰ নিৰ্দেশনাৰ জৰিয়তে গ্ৰাহকক সঠিক প্ৰডাক্ট মেচ নিৰ্বাচন কৰাত সহায় কৰা। আমি তৎকালীন সেৱা আৰু যোগানৰ সৈতে ভাল মানৰ ব্ৰেণ্ডেড সামগ্ৰী প্ৰদান কৰোঁ।

                </div>
              </div>
            </div>
            <Footer/>

            </>
        );
    }
    else if(props.page === "products"){
      const css = `

.card {
max-width: 30em;
flex-direction: row;
background-color: #696969;
border: 0;
box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
margin: 3em auto;
}
.card.dark {
color: #fff;
}
.card.card.bg-light-subtle .card-title {
color: dimgrey;
}

.card img {
max-width: 25%;
margin: auto;
padding: 0.5em;
border-radius: 0.7em;
}
.card-body {
display: flex;
justify-content: space-between;
}
.text-section {
max-width: 60%;
}
.cta-section {
max-width: 40%;
display: flex;
flex-direction: column;
align-items: flex-end;
justify-content: space-between;
}
.cta-section .btn {
padding: 0.3em 0.5em;
}
.card.bg-light-subtle .cta-section .btn {
background-color: #898989;
border-color: #898989;
}
@mediLinkscreen and (max-width: 475px) {
.card {
font-size: 0.9em;
}
}
`
        return (
            <>
            <style>{css}</style>
            <div className="products">
            <div className="container">
                <Card bg="dark" redirect="/products/tmt-bar" color="btn-light" assamese="টিএমটি ষ্টীল" url="/Products/tmt.avif" title="TMT BAR"/>
                <Card bg="bg-success text-white opacity-5" redirect="/products/cement" color="btn-dark" assamese="চিমেণ্ট" url="/Products/cement.webp" title="CEMENT"/>
                <Card bg="bg-danger-subtle" redirect="/products/sanitary" color="btn-dark" assamese="চেনিটাৰী" url="/Products/sani.webp" title="SANITARY"/>
                <Card bg="bg-primary-subtle" redirect="/products/ply" color="btn-dark" assamese="প্লাই ডোৰ" url="/Products/door1.avif" title="PLY DOOR"/>
                <Card bg="bg-secondary-subtle" redirect="/products/aluminiun-door" color="btn-dark" assamese="এলুমিনিয়ামৰ দুৱাৰ" url="/Products/win.jpeg" title="ALUMINIUM DOOR"/>
                <Card bg="bg-info" redirect="/products/aluminiun-window" color="btn-dark" assamese="এলুমিনিয়াম খিৰিকী" url="/Products/win.jpeg" title="ALUMINIUM WINDOW"/>
                <Card bg="bg-dark-subtle" redirect="/products/wooden" color="btn-dark" assamese="কাঠৰ দৰ্জা" url="/Products/wooden.webp" title="WOODEN DOOR"/>
                <Card bg="bg-success-subtle" redirect="/products/flush" color="btn-dark" assamese="ফ্লাশ ডোৰ" url="/Products/door2.webp" title="FLUSH DOOR"/>
                <Card bg="bg-warning-subtle" redirect="/products/fabrication" color="btn-dark" assamese="ফেব্ৰিকেচন" url="/Products/fab.avif" title="FABRICATION"/>
                <Card bg="bg-info-subtle" redirect="/products/paint" color="btn-dark" assamese="ৰং" url="/Products/paint.jpeg" title="PAINT"/>
                <Card bg="bg-warning" redirect="/products/roofing" color="btn-light" assamese="ৰুফিং" url="/Products/roof.jpg" title="ROOFING"/>
</div>
            </div>
            <Footer/>
            </>
        );
    }
    else if(props.page === "contact"){
        return (
            <>
            <div className="contact">
            <iframe title={"map"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114578.17298158274!2d91.74175550153122!3d26.178198240587363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375a587b7a7aba1f%3A0x68a1b668ecf5d94b!2sNillum%20Hardware!5e0!3m2!1sen!2sin!4v1721411413323!5m2!1sen!2sin" width="600" height="450" style={{border:0,width: '90%',marginLeft:'5%'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            <div className="address-details" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <div className="address" style={{textAlign:'center'}}>
            <h4><i className="fa fa-map-marker"></i> Forest Gate, Narengi, Guwahati- 781026</h4>
            <h4>Assam, India</h4>
            <h4><i className="fa fa-phone"></i> +91 8638900496, 9957788505</h4>
            </div>
            </div>
            </div>
            <div>
            </div>
            <Footer/>
            </>
        );
    }
}
